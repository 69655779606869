.border-white{
    font-weight: bold;
}
.dropdown-toggle{
-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)!important;
box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)!important;
}
#navbarCollapse3{
    height:41px;
}
