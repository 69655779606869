.check{
background-color: rgb(235, 0, 0);
color: blue;
}

.padding{
    padding: 10px;
}

div.scrollinRules
{
    background-color: #F5F5F5;
    font-weight: bold;
    height:420px;
    overflow:scroll;
    overflow-x:hidden;
}
