.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 5s linear;
          animation: App-logo-spin infinite 5s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (min-width : 1321px) {
  .App {
    margin: 5px;
  }
}

.border-white{
    font-weight: bold;
}
.dropdown-toggle{
box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)!important;
}
#navbarCollapse3{
    height:41px;
}

.CardText{
    padding: 2px;
    padding-top: 10px;
    
    }

.FieldSet{
    border: 20px;
    background-color:red;
    }


    .alert{
        position: absolute;
        left: 600px;
        top:-900px;
        z-index: 2;
    }
.border{
    border-style: dotted;
    background-color: rgb(240, 240, 240);
    padding: 10px;
}

.radius{
    border-radius: 5px
}

.selected-component{
    border-style: solid;
    border-color: rgb(19, 185, 27)
}
.cursor{
    cursor: pointer;
}


.padding-rule{
    padding: 30px
}

input[type=checkbox] {
    -webkit-transform: scale(2.0);
            transform: scale(2.0);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 16px;
    width: 16px;
    border: 1px solid white;
    border-radius: 30%
}
input[type="checkbox"]:checked {
    background: #00695c
  }
  
  input[type="checkbox"]:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
  }
  
  input[type="checkbox"]:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  
  input[type="checkbox"]:after {
    content: '';
    position: relative;
    left: 35%;
    top: 18%;
    width: 35%;
    height: 50%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: none;
  }
  
  input[type="checkbox"]:checked:after {
    display: block;
  }
  
  input[type="checkbox"]:disabled:after {
    border-color: #7b7b7b;
  }



.contentTable {
  padding: 90px; }

.center-col {
  left: -4px;
  height: 570px;
  overflow: scroll;
  overflow-x: hidden; }

.center-colModal {
  left: -1px;
  height: 590px;
  overflow: scroll;
  overflow-x: hidden; }

.setColor {
  color: white; }

/* SASS File */
.fileUpload input[type=file] {
  display: none; }

.fileUpload .styleButton {
  width: 100%;
  font-size: 14px;
  background: #BDFFF3;
  border: 2px solid #CCC;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  outline: none;
  text-align: center; }

.fileUpload.active input[type=button] {
  background: #666;
  border-color: #666;
  color: #FFF; }

#modal-body-card-form {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
.center{
    margin:0px auto;
    width:350px;
    display:none;
}

/* Important part */
.modal-dialog{
    overflow-y: visible !important;
    overflow-y: initial !important;
}
.modal-body-grid{
    height: 600px;
    overflow-y: auto;
}
.padding-col{
    padding: 15px;
    
    }

.padding{
    padding: 10px;
    cursor: pointer;
}

.selected{
    background-color: blue;
}
    
.padding-resumen{
    padding: 20px;
    border-style: dotted;
    border-color: rgb(163, 161, 161);
    background-color: white
}
.cursor{
    cursor: pointer;
}
div.scrollingDiv
{
    background-color: #F5F5F5;
    font-size: 18px;
    font-weight: bold;
    left: -1px;
    height:320px;
    overflow:scroll;
    overflow-x:hidden;
}
.check{
background-color: rgb(235, 0, 0);
color: blue;
}

.padding{
    padding: 10px;
}

div.scrollinRules
{
    background-color: #F5F5F5;
    font-weight: bold;
    height:420px;
    overflow:scroll;
    overflow-x:hidden;
}

.border-render{
    border-style: dotted;
    border-color: rgb(163, 161, 161);
    border-top-width: 0px;
    
}

input[type=radio] {
    border: 0px;
    width: 80%;
    height: 1.5em;
}

div.scrollingDivField
{
   
    left: -1px;
    height:920px;
    overflow:scroll;
    overflow-x:hidden;
}


div.scrollingDivDocs
{
   
    left: -1px;
    height:650px;
    overflow:scroll;
    overflow-x:hidden;
}
.center-col {
  flex: 6 1;
  overflow-y: scroll; }

.setColor {
  color: white; }

/* SASS File */
.fileUpload input[type=file] {
  display: none; }

.fileUpload .styleButton {
  width: 100%;
  font-size: 14px;
  background: #BDFFF3;
  border: 2px solid #CCC;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  outline: none;
  text-align: center; }

.fileUpload.active input[type=button] {
  background: #666;
  border-color: #666;
  color: #FFF; }

.CKEditor {
    margin: 25px;
  }
  
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-gear{
  justify-content: center !important;
  align-items: center !important
}


.visible {
  visibility: visible;
  width: 100%;
  -webkit-transition: width 0.1s linear;
  transition: width 0.1s linear;
}
.hidden {
  visibility: hidden;
  width: 0%;
  -webkit-transition:  width 0.1s linear;
  transition:  width 0.1s linear;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #14141400;; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #14141400; 
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:20px;
	background-color:rgb(240, 119, 6);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:12px;
}


.float2{
	position:fixed;
	width:60px;
	height:60px;
	bottom:120px;
	right:20px;
	background-color:rgb(240, 119, 6);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float2{
	margin-top:12px;
}


