body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-gear{
  justify-content: center !important;
  align-items: center !important
}


.visible {
  visibility: visible;
  width: 100%;
  transition: width 0.1s linear;
}
.hidden {
  visibility: hidden;
  width: 0%;
  transition:  width 0.1s linear;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #14141400;; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #14141400; 
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:20px;
	background-color:rgb(240, 119, 6);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:12px;
}


.float2{
	position:fixed;
	width:60px;
	height:60px;
	bottom:120px;
	right:20px;
	background-color:rgb(240, 119, 6);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float2{
	margin-top:12px;
}

