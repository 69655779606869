.border-render{
    border-style: dotted;
    border-color: rgb(163, 161, 161);
    border-top-width: 0px;
    
}

input[type=radio] {
    border: 0px;
    width: 80%;
    height: 1.5em;
}

div.scrollingDivField
{
   
    left: -1px;
    height:920px;
    overflow:scroll;
    overflow-x:hidden;
}


div.scrollingDivDocs
{
   
    left: -1px;
    height:650px;
    overflow:scroll;
    overflow-x:hidden;
}