.border{
    border-style: dotted;
    background-color: rgb(240, 240, 240);
    padding: 10px;
}

.radius{
    border-radius: 5px
}

.selected-component{
    border-style: solid;
    border-color: rgb(19, 185, 27)
}
.cursor{
    cursor: pointer;
}


.padding-rule{
    padding: 30px
}

input[type=checkbox] {
    transform: scale(2.0);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 16px;
    width: 16px;
    border: 1px solid white;
    border-radius: 30%
}
input[type="checkbox"]:checked {
    background: #00695c
  }
  
  input[type="checkbox"]:hover {
    filter: brightness(90%);
  }
  
  input[type="checkbox"]:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  
  input[type="checkbox"]:after {
    content: '';
    position: relative;
    left: 35%;
    top: 18%;
    width: 35%;
    height: 50%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  input[type="checkbox"]:checked:after {
    display: block;
  }
  
  input[type="checkbox"]:disabled:after {
    border-color: #7b7b7b;
  }


