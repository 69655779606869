.center-col {
    flex: 6;
    overflow-y: scroll;
  }
  .setColor{
    color:white;
  }
 /* SASS File */
.fileUpload{
  input[type=file]{
    display: none; 
  } 
  .styleButton{
    width:100%;
    font-size:14px;
    background: #BDFFF3;
    border: 2px solid #CCC;
    border-radius: 4px;
    padding: 12px;
    font-weight: 700;
    outline: none;
    text-align: center;
  }
   &.active{
     input[type=button]{
       background: #666;
       border-color: #666;
       color: #FFF;
     }
   }
 }