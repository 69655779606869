.padding-col{
    padding: 15px;
    
    }

.padding{
    padding: 10px;
    cursor: pointer;
}

.selected{
    background-color: blue;
}
    