.center-col {
      left: -4px;
      height:570px;
      overflow:scroll;
      overflow-x:hidden;
  }
  .center-colModal {
    left: -1px;
    height:590px;
    overflow:scroll;
    overflow-x:hidden;
}
  .setColor{
    color:white;
  }
 /* SASS File */
.fileUpload{
  input[type=file]{
    display: none; 
  }
   .styleButton{
    width:100%;
    font-size:14px;
    background: #BDFFF3;
    border: 2px solid #CCC;
    border-radius: 4px;
    padding: 12px;
    font-weight: 700;
    outline: none;
    text-align: center;
  }
   &.active{
     input[type=button]{
       background: #666;
       border-color: #666;
       color: #FFF;
     }
   }
 }