.CardText{
    padding: 2px;
    padding-top: 10px;
    
    }

.FieldSet{
    border: 20px;
    background-color:red;
    }


    .alert{
        position: absolute;
        left: 600px;
        top:-900px;
        z-index: 2;
    }