.padding-resumen{
    padding: 20px;
    border-style: dotted;
    border-color: rgb(163, 161, 161);
    background-color: white
}
.cursor{
    cursor: pointer;
}
div.scrollingDiv
{
    background-color: #F5F5F5;
    font-size: 18px;
    font-weight: bold;
    left: -1px;
    height:320px;
    overflow:scroll;
    overflow-x:hidden;
}